 /* *********** Rounded css loader round spinner round ********************** */

 .roundSpinner {
	border-width: 2px;
    border-style: solid;
    border-color: #000000 #000000 #000000 transparent;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: spin 0.5s infinite;
    position: relative;
    margin: 3% auto;
  }
  .roundSpinner:before, .roundSpinner:after {
	content: '';
	/* width: 8px;
	height: 8px; */
	border-radius: 50%;
	background: #7D5195;
	position: absolute;
	left: 8px;
  }
  .roundSpinner:before {
	top: 8px;
  }
  .roundSpinner:after {
	bottom: 8px;
  }
  
  @keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	  }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	  }
  }
  @-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	  }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	  }
  }

  
.lds-ripple {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	margin: 20% 0% 0% 41%;
  }
  .lds-ripple div {
	position: absolute;
	border: 4px solid rgb(7, 7, 7);
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
	0% {
	  top: 28px;
	  left: 28px;
	  width: 0;
	  height: 0;
	  opacity: 1;
	}
	100% {
	  top: -1px;
	  left: -1px;
	  width: 58px;
	  height: 58px;
	  opacity: 0;
	}
  }

/* 
  / ************************************************* Image uploading loader  ********************************* / */
.loaderFullDisplay{
    position: absolute;
    background-color: #000000e6;
    width: 100%;
    height: 100%;
    z-index: 999;
}
  .lds-rippleimageupload {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 50% 0% 0% 42%;
    }
    .lds-rippleimageupload div {
    position: absolute;
    border: 1px solid rgba(19, 19, 19, 0);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    background-color: #fff;
    }
    .lds-rippleimageupload div:nth-child(2) {
    animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
    }


  /* / Rounded loader with round transparent box  / */
.roundSpinnerWithBox,
.roundSpinnerWithBox:after {
  border-radius: 50%;
  width: 4em;
  height: 4em;
}
.roundSpinnerWithBox {
  margin: 10% auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.9em solid rgba(0,0,0, 0.2);
  border-right: 0.9em solid rgba(0,0,0, 0.2);
  border-bottom: 0.9em solid rgba(0,0,0, 0.2);
  border-left: 0.9em solid #000000;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
