.Documents 
{
  border-radius: 6px;
  border: solid 1px #ccc;
  width: 100%;
  max-height: 70%;
  margin: auto;

  }
  .Documents img{
    border-radius: 6px;
    display: inline-block;
    object-fit: fill;
  }
 
.candidatenamedispute{
  top: 30%;
  position: absolute;
}
.DisputeApproved{
  text-align: center;
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  color: white;
  cursor: pointer;
  background-color:#03c00c;
  border: none;
  padding: 10px 30px;
  display: inline-block;
  margin-top: 10px;
  transition: 0.2s;
}

.DisputeApproved:hover{
  /* transform: scale(1.05); */
  background-color: #03c00c;
  border: none;
}

.Disputebtn
{
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
  cursor: pointer;
  background-color:#f02076;
  border: none;
  padding: 10px 30px;
  display: inline-block;
  margin-top: 10px;
  transition: 0.2s;
}
.Disputebtn:hover{
  /* transform: scale(1.05); */
  background-color: #f02076;
  border: none;
}

.DisputebtnCancel{
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  border: none;
  padding: 10px 30px;
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
}
.resultCard{
  text-align: center;
  padding: 10px;
  margin: 10px;
}
.resultCard img{
  border-radius: 50px;
}