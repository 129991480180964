body {
  font-family: "Lato", sans-serif;
}

.main-head {
  height: 150px;
  background: #fff;
}

.sidenavc {
  height: 100%;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 20px;
}

.mainc {
  padding: 0px 10px;
}

@media screen and (max-height: 450px) {
  .sidenavc {
    padding-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .login-formc {
    margin-top: 10%;
  }

  .register-formc {
    margin-top: 10%;
  }
}

@media screen and (min-width: 768px) {
  .mainc {
    margin-left: 40%;
  }

  .sidenavc {
    width: 40%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .login-formc {
    margin-top: 80%;
  }

  .register-formc {
    margin-top: 20%;
  }
}

.login-main-textc {
  margin-top: 20%;
  padding: 60px;
  color: #fff;
}

.login-main-textc h2 {
  font-weight: 300;
}

.btn-black {
  background-color: #000 !important;
  color: #fff;
}
