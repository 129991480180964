/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: sans-serif; */
  font-family: 'Poppins', sans-serif;
}

body {
  overflow-x: hidden;
}

label{
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #626262 !important;
  padding: 0px;
}

.editBtn{
  border: 1px solid #0381ff;
  color: #0381ff;
  border-radius: 4px;
  padding: 4px 8px;
  transition: 0.2s;

}

.editBtn:hover{
  background: #0381ff;
  color: #ffffff;
  transform: translateY(-3px);
}

.registerBtn{
  border: 1px solid #17c703;
  color: #17c703;
  border-radius: 4px;
  padding: 4px 8px;
  transition: 0.2s;
}

.registerBtn:hover{
  background: #17c703;
  color: #ffffff;
  transform: translateY(-3px);
}

.transactionBtn{
  border: 1px solid #bcab0f;
  color: #bcab0f;
  border-radius: 4px;
  padding: 4px 8px;
  transition: 0.2s;
}

.transactionBtn:hover{
  background: #bcab0f;
  color: #ffffff;
  transform: translateY(-3px);
}

.locationBtn{
  border: 1px solid #0c9e99;
  color: #0c9e99;
  border-radius: 4px;
  padding: 4px 8px;
  transition: 0.2s;
}

.locationBtn:hover{
  background: #0c9e99;
  color: #ffffff;
  transform: translateY(-3px);
}

ul .pagination li{
  margin: 4px 10px !important;
}
.containers {
  position: relative;
  width: 100%;
  margin-left: -12px;
}

.navigation {
  position: fixed;
  width: 280px;
  height: 100%;
  background: #003147;
  transition: 0.5s;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

.navigation::-webkit-scrollbar {
  width: 8px;
}

.navigation::-webkit-scrollbar-track {
  background: #003147;
}

.navigation::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 6px;
  border: 3px solid #003147;
}

.navigation.active {
  width: 110px;
}

.navigation ul {
  position: absolute;
  top: 0;
  padding-left: 0.5rem;
  padding-right: 1rem;
  left: 0;
  width: 100%;
  margin: 10px;
}
.navigation ul li {
  padding-left: 1rem;
  position: relative;
  width: 100%;
  list-style: none;
}
.navigation ul li:hover {
  width: 100%;
  /* border-bottom-left-radius: 30px;
  border-top-left-radius: 30px; */
  /* padding-left: 20px; */
  border-radius: 15px;
  background: #03a9f4;
}

.navigation #active {
  /* border-bottom-left-radius: 30px;
  border-top-left-radius: 30px; */

  border-radius: 15px;
  background-color: #dd2ac5;
}

.navigation ul li:nth-child(1) {
  margin-bottom: 20px;
}
.navigation ul li:nth-child(1):hover {
  background: transparent;
}
.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #fff;
}
.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.navigation ul li a .icon .fa {
  color: #fff;
  font-size: 24px;
  margin-left: -30px;
}

.navigation ul li .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  white-space: nowrap;
  margin-left: -30px;
  color: #fff;
}

.navigation .accordion .accordion-header{
  background: transparent !important;
}

.navigation .accordion .accordion-item{
  background: transparent !important;
  border: none !important;
}

.navigation .accordion .accordion-button{
  background: transparent !important;
  color: #ffffff;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  font-weight: 400;
}

.navigation .accordion-button:focus {
  font-weight: 500 !important;
}

.navigation .accordion-button:after{
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.navigation .accordion li a .icon {
  position: relative;
  display: block;
  min-width: 50px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.navigation .accordion li a .icon .fa {
  color: #fff;
  font-size: 22px;
  margin-left: -30px;
}
.navigation .accordion li .title {
  font-size: 15px;
}




.navbar-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main {
  position: absolute;
  width: calc(100%-250px);
  left: 270px;
  min-height: 100vh;
  background: #f5f5f5;
  transition: 0.5s;
  right: 0px;
}
.main.active {
  width: calc(100%-60px);
  left: 60px;
}
.main .topbar {
  position: sticky;
  width: 100%;
  background: #fff;
  height: 60px;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media(max-width:400px){
   .main .topbar {
  padding: 0 0px;
}
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.toggle::before {
  content: "\f0c9";
  font-family: fontAwesome;
  position: absolute;
  width: 100%;
  height: 100%;
  line-height: 60px;
  font-size: 25px;
  text-align: center;
  color: #111;
}
.search {
  position: relative;
  width: 400px;
  margin: 0 10px;
}
.search label {
  position: relative;
  width: 100%;
}
.search label input {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  outline: none;
  border: 1px solid black;
}
.search label .fa {
  position: absolute;
  left: 15px;
  top: 13px;
}
.user {
  position: relative;
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}
.user img {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  object-fit: cover;
}
.cardBox {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.cardBox .card {
  position: relative;
  background: #fff;
  padding: 0px;
  margin-top: 10%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.cardBox .card .numbers {
  position: relative;
  font-size: 2rem;
  font-weight: 500;
}
.cardBox .card .cardTitle {
  color: #999;
  text-align: center;
}
.cardBox .card .iconBox {
  font-size: 2.5em;
  color: #03a9f4;
}
/*Now make it responsive*/
@media (max-width: 992px) {
  .navigation {
    left: -300px;
  }
  .navigation.active {
    left: 0;
    width: 300px;
  }
  .main {
    width: 100%;
    left: 0;
  }
  .main.active {
    width: calc(100%-300px);
    left: 300px;
  }
  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownOnNavbarUL {
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 20px;
  right: 0px;
  width: 200px;
  background-color: white;
  font-weight: bold;
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdownOnNavbarLI {
  padding: 8px 16px;
  border-bottom: 1px solid #e5e5e5;
}

.dropdownOnNavbarLI a {
  /* color: #000; */
  text-decoration: none;
  cursor: pointer;
}

.dropdownOnNavbarLI:hover {
  border-bottom: none;
  cursor: pointer;
}
.circle {
  font-size: 21px;
  background-color: #fffb00;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fffb00;
  margin: auto;
  color: rgb(17, 17, 17);
  text-align: center;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CoordinatorCount {
  font-size: 24px;
  background-color: #7ee0f8fb;
  display: block;
  width: 60px;
  height: 60px;
  line-height: 50px;
  border-radius: 50%;
  border: 1px solid #91f599;
  margin: auto;
  color: rgb(17, 17, 17);
  text-align: center;
}
.reaload {
  height: 30px;
  width: 120px;
  margin: -20px -50px;
  position: relative;
  top: 50%;
  left: 50%;

  text-align: center;
}
.errorMsg 
{
    font-size: 14px;
    color: #cc0000;
    margin-bottom: 10px;
}
.ReactModal__Content{
  width:50%;
  height:auto;
  margin: 0 auto;
  border-radius: 15px !important;
  box-shadow:  5px 5px #e0e0e1!important
}
.ReactModal__Content h2{
  background-color: #003147;
  color:#fff;
  padding:10px;
}

.formLabel{
  padding:10px;
  font-weight:600;
}
.formInput{  
  padding:10px;    
  border-radius: 8px;
}
.formButton{
  
  padding:10px 15px;
  float:right;
  /* background-color: #003147; */
}
.success-message {
  display: block;
  padding: 10px;
  /* background-color: #2ecc71; */
  color: white;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 10px;
  width:fit-content;
  margin: 0 auto;
}
.mr-5{
  margin-right:13px;
}
.mt-50{
  margin-top: 50px;
}
