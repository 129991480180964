h1 {
    border: dashed 2px blue;
      width: 200px;
      height: 200px;
      border-radius: 100px;
      text-align: center;
      line-height: 60px;
      
    }

    .numberCircle-center_count1{
        width:40px;
        line-height: 40px;
        border-radius: 50%;
        text-align: center;
        background-color:#9ACD32;
        text-decoration: none;
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        margin: auto;
        
    }

    .numberCircle-center_count2{
        width:80px;
        line-height: 80px;
        border-radius: 50%;
        text-align: center;
        background-color:#aa5fff;
        text-decoration: none;
        color: #000000;
        font-size: 28px;
        font-weight: 400;
        /* border: 2px solid rgb(15, 1, 1); */
        margin: auto;
        
    }