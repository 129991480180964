.custom-toggle {
    display: inline-block;
    position: relative;
    width: 90px; /* Increased width for a wider toggle */
    height: 28px;
    margin: 0 10px;
}

.custom-toggle input {
    display: none;
}

.slider {
    position: absolute;
    top: 0;
    left: 5px; /* Adjusted left position for the gap */
    right: 9px; /* Adjusted right position for the gap */
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    cursor: pointer;
}

.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    z-index: 1; /* Ensure the white circle is above the label */
}

.toggle-label {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    font-size: 12px;
    font-weight:550;
    color: #161515;
    cursor: pointer;
    z-index: 2; /* Ensure the label is above the white circle */
}

.custom-toggle input:checked + .slider {
    background-color: #2ecc71;
}

.custom-toggle input:checked + .slider:before {
    transform: translateX(46px); /* Adjusted translateX value */
}
