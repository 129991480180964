a {
    text-decoration: none !important;
}
body{
    background-color: #f6f6f6;
    font-family: "Poppins", sans-serif !important;
    scrollbar-width: thin;
    scroll-behavior: smooth;
}
 
body::-webkit-scrollbar {
    width: 10px;
  }
  
body::-webkit-scrollbar-track {
    background: #dfdfdf;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #073c7c;
    border-radius: 6px;
    border: 3px solid #dfdfdf;
  }
  

.widthPer10{width: 10% !important;}
.widthPer20{width: 20% !important;}
.widthPer30{width: 30% !important;}
.widthPer40{width: 40% !important;}
.widthPer44{width: 44% !important;}
.widthPer46{width: 46% !important;}
.widthPer48{width: 48% !important;}
.widthPer50{width: 50% !important;}
.widthPer60{width: 60% !important;}
.widthPer70{width: 70% !important;}
.widthPer80{width: 80% !important;}
.widthPer90{width: 90% !important;}
.widthPer100{width: 100% !important;}
.boldWeight{font-weight: 700 !important;}
.fontSize10{font-size: 10px !important;}
.fontSize12{font-size: 12px !important;}
.fontSize14{font-size: 14px !important;}
.fontSize16{font-size: 16px !important;}
.fontSize18{font-size: 18px !important;}
.fontSize20{font-size: 20px !important;}
.paddingZero{padding: 0 !important;}
.paddingAll4{padding:4px !important;}
.paddingAll6{padding:6px !important;}
.paddingAll10{padding:10px !important;}
.paddingAll14{padding:14px !important;}
.paddingAll16{padding:16px !important;}
.paddingAll20{padding:20px !important;}
.paddingAll22{padding:22px !important;}
.paddingAll24{padding:24px !important;}
.paddingAll28{padding:28px !important;}
.paddingAll30{padding:20px !important;}
.paddingZero10{padding: 0 10px !important;}

.paddingLeftRight10{padding-left: 10px !important;padding-right: 10px !important;}
.paddingLeftRight20{padding-left: 20px !important;padding-right: 20px !important;}
.paddingLeftRight30{padding-left: 30px !important;padding-right: 30px !important;}
.paddingLeftRight40{padding-left: 40px !important;padding-right: 40px !important;}
.paddingLeftRight50{padding-left: 50px !important;padding-right: 50px !important;}
.paddingLeftRight60{padding-left: 60px !important;padding-right: 60px !important;}
.paddingLeftRight70{padding-left: 70px !important;padding-right: 70px !important;}
.paddingLeftRight80{padding-left: 80px !important;padding-right: 80px !important;}
.paddingLeftRight90{padding-left: 90px !important;padding-right: 90px !important;}
.paddingLeftRight14{padding: 0px 14px !important;}
.paddingLeftRight16{padding: 0px 16px !important;}
.paddingLeftRight18{padding: 0px 18px !important;}

.paddingLeft2{padding-left: 2px !important;}
.paddingLeft4{padding-left: 4px !important;}
.paddingLeft6{padding-left: 6px !important;}
.paddingLeft8{padding-left: 8px !important;}
.paddingLeft10{padding-left: 10px !important;}
.paddingLeft12{padding-left: 12px !important;}
.paddingLeft14{padding-left: 14px !important;}
.paddingLeft16{padding-left: 16px !important;}
.paddingLeft18{padding-left: 18px !important;}

.paddingTopBottom10{padding-top: 10px;padding-bottom: 10px;}
.paddingTopBottom12{padding-top: 12px;padding-bottom: 12px;}
.paddingTopBottom14{padding-top: 14px;padding-bottom: 14px;}
.paddingTopBottom16{padding-top: 16px;padding-bottom: 16px;}
.paddingTopBottom18{padding-top: 18px;padding-bottom: 18px;}
.paddingTopBottom20{padding-top: 20px;padding-bottom: 20px;}
.paddingTopBottom22{padding-top: 22px;padding-bottom: 22px;}

.divider-2{margin-top: 2px !important;}
.divider-4{margin-top: 4px !important;}
.divider-6{margin-top: 6px !important;}
.divider-8{margin-top: 8px !important;}
.divider-10{margin-top: 10px !important;}
.divider-12{margin-top: 12px !important;}
.divider-14{margin-top: 14px !important;}
.divider-16{margin-top: 16px !important;}
.divider-20{margin-top: 20px !important;}
.divider-30{margin-top: 30px !important;}
.divider-40{margin-top: 40px !important;}
.divider-50{margin-top: 50px !important;}
.divider-60{margin-top: 60px !important;}
.divider-70{margin-top: 70px !important;}
.divider-80{margin-top: 80px !important;}
.divider-90{margin-top: 90px !important;}
.divider-100{margin-top: 100px !important;}
.divider-110{margin-top: 110px !important;}
.leftSpace4{margin-left: 4px !important;}

.buttonColor{color: #fff;background-color: #4b5499;}
.colorLink{color: #03a9f4 !important;}
.boxShadowAll2{box-shadow: 0px 0px 0px 2px #f1f1f1;}
.boxShadowAll4{box-shadow: 0px 0px 0px 4px #efefef;}
.boxShadowAll6{box-shadow: 0px 0px 0px 6px #f1f1f1;}
.boxShadowAll8{box-shadow: 0px 0px 0px 8px #f1f1f1;}
.boxShadowAll10{box-shadow: 0px 0px 0px 10px #f1f1f1;}

.pagination {
    cursor: pointer;
    margin-left: 15px;
}

/* for button ui */
li.btn {
    margin: 0px 4px !important;
}
li.btn:hover {
    background-color: #186dee;
    border:unset
}

/* for cordinator record per page ui */
@media (max-width: 400px){
 .span.cordintorText.fs-6 {
    font-size: 12px;
 }
}
    

.marginAllZero{margin: 0px !important;}
.marginAll10{margin: 10px !important;}
.marginAll12{margin: 12px !important;}
.marginAll14{margin: 14px !important;}
.marginAll16{margin: 16px !important;}
.marginAll18{margin: 18px !important;}
.marginTop6Per{margin-top: 6% !important;}
.marginTop7Per{margin-top: 7% !important;}
.marginTop12Per{margin-top: 12% !important;}
.marginTopPer10Desktop{margin-top: 10% !important;}
.marginTop7PerDesktop {margin-top: 6% !important;}

.marginLeftRight10{margin: 0 10px !important;}
.heightAll100{height:100px !important;padding:10px;}
.heightAll110{height:110px !important;padding:10px;}
.heightAll120{height:120px !important;padding:10px;}
.heightAll130{height:130px !important;padding:10px;}
.zoom:hover {
    transform: scale(2.5); 
}

.setUnselect{
    user-select: none;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  
.colorWhiteIcon{color: #ffffff !important;}
.error_div{color: #e73636;display: block;padding-top: 4px;font-size: 14px;}
.hide{display: none;}
.display{display: block;}
.font12Weight700{
    font-size: 12px;
    font-weight: 700;
}

.font18Weight700{
    font-size: 18px;
    font-weight: 700;
}
.font12Weight500{
    font-size: 12px;
    font-weight: 500;
}
.font12Weight600{
    font-size: 12px;
    font-weight: 600;
}
.font16Weight600{
    font-size: 16px;
    font-weight: 600;
}
.headerForViewPdf{
    background-color: #03a9f4;
    padding: 16px;
    color: #ffffff;
    border-radius: 0px 0px 6px 6px;
}
.hideInDesktop{
    display: none !important;
}
.inputSearch{
  border: 1px solid #ccc;
  background: #ffffff;
  width: 100%;
  border-radius: 20px;
  padding-left: 16px;
}
.selectBox{
    width: 100%;
    padding: 0.1rem 0.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.selectBoxNew{
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formBoxForSelect{
    border: 1px solid #ccc;
    padding: 0px 10px 10px 10px;
    border-radius: 4px;
    margin-top: 4px;
}
.videoBox{
    border: 0 solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px !important;
}

.boxShadowAll{
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 10px;
    padding: 16px;
}
.list-group-items {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
}
.list-group-items a{color: #ffffff !important;}
.marginLeft10{margin-left: 10px;}
.paddingLeft10{padding-left: 10px;}
.width10Per{width: 10% !important;padding: 0;}
.width90Per{width: 90% !important;}
.fontWeight600{font-weight: 600 !important;}
.fontWeight700{font-weight: 700 !important;}
.fontWeight800{font-weight: 800 !important;}
.fontWeight900{font-weight: 900 !important;}

.h1Name{font-size: 28px;}
.bottom20{bottom: 20px;}
.width80Per{width: 80% !important;}
.width90Per{width: 90% !important;}
.padding10Desktop{padding: 10px;}
.backGroundColorWhite{background-color: #ffffff !important;}
.backGroundColorBlue{background-color: #03a9f4 !important;color: #ffffff !important;}
.backGroundColorGray{background-color: #f3f3f3 !important;}
.zIndex0{z-index: 0 !important;}
.zIndex999{z-index: 999 !important;}
.positionInherit{position: inherit !important;}
.floatRight{float: right !important;}
.sticky {
    position: fixed !important;
    top: -5px !important;
    left: 0 !important;
    width: 100% !important;
}

.tdWidth140px{
 max-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
}
.btn-padding-new{
    padding: 0.375rem 3.75rem !important;
}
@media only screen and (max-width: 768px){
    .padding10Desktop{padding: 0;}
    .marginTopPer10{margin-top: 10% !important;}
    .marginTopPer20{margin-top: 20% !important;}
    .marginTopPer30{margin-top: 30% !important;}
    .marginTopPer40{margin-top: 40% !important;}
    .marginTopPer50{margin-top: 50% !important;}
    .marginTopPer60{margin-top: 60% !important;}
    .marginTop7PerDesktop {margin-top: 11% !important;}
    .headerForViewPdfMobile{
        background-color: #499ed3;
        padding: 16px;
        color: #ffffff;
        border-radius: 0px 0px 6px 6px;
        display: flex;
    }
    .headerForViewPdfMobileSmall{
        background-color: #499ed3;
        padding: 10px 10px 10px 16px;
        color: #ffffff;
        border-radius: 0px 0px 6px 6px;
        display: flex;
    }
    .hideInMobile{
        display: none !important;
    }
    .hideInDesktop{
        display: flex !important;
    }
    .videoBox{
        border: 1px solid #ccc;
        border-radius: 4px;
        margin-top: 10px;
    }
    
}

.btn:hover {
    color: #ffffff;
}


.errorMsg {
    font-size: 14px;
    color: #cc0000;
    margin-bottom: 12px;
  }

   
#datepicker{ 
        
       
        width: 250px;
}
