.block-div {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 510;
  color: #232323;
}

.main-h2 {
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
}