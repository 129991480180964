#collegenameDiv
{
    margin-top: 15px;
}
#collegetypeDiv
{
    margin-top: 15px;
}
#address1Div
{
    margin-top: 15px;
}
#address2Div
{
    margin-top: 15px;
}
#stateDiv
{
    margin-top: 15px;
}
#cityDiv
{
    margin-top: 15px;
}
#pincodeDiv
{
    margin-top: 15px;
}
#phoneDiv
{
    margin-top: 15px;
}
#websiteDiv
{
    margin-top: 15px;
}
#priciplenameDiv
{
    margin-top: 15px;
}
#priciplenumberDiv
{
    margin-top: 15px;
}
#approvestatusDiv
{
    margin-top: 15px;
}
#uploadlogoDiv
{
    margin-top: 15px;
}
#btnSubmitCollege
{
    margin-top: 15px;
    margin-left: 100px;
}
#btnCancelCollege
{
    margin-top: 15px;
    margin-left: 70px;
}

#collegetype
{
    margin-left: 70px;
}
.errorMsg 
{
    color: #cc0000;
    margin-bottom: 10px;
}
#btnCancelCollege
{
    margin-top: 20px;
}
#btnUpdateCollege
{
    margin-top: 20px;
    margin-left: 100px;
}
.electionStartDateDiv
{
    margin-top: 15px;
}
.electionEndDateDiv
{
    margin-top: 15px;
}
.electionStartTimeDiv
{
    margin-top: 15px;
}
.electionEndTimeDiv
{
    margin-top: 15px;
}
.electionStatusDiv
{
    margin-top: 20px;
}
.btnElection
{
    margin-left: 150px;
}
.electionStatusDropdown
{
    float: right;
    width: 250px;
    height: 30px;
}
.errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
  }

