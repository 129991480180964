.image {
    
    width:20px;
  
    height: 20px;
 
    margin-bottom: 100px;
     margin-left: 5px;
    align-content: center;
  }
 
  
  .trick {
    display: inline-block;
    vertical-align: middle;
    height: 150px;
  }
  
  .image:hover {
    box-shadow: 0px 5px 20px .9px #3F3F3F;
  }
  
  .image:hover {
    -webkit-transform: scale(1.12);
    transform: scale(1.12);
    -webkit-transition: 1.6s ease-in-out;
    transition: 1.6s ease-in-out;
  }
  
  
  
  .trick:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  #overlay {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    padding: 0 0 0 0;
    opacity: 1.0;
    -webkit-transition: opacity 2.25s ease;
    -moz-transition: opacity 10.25s ease;
  }
  
  #box:hover #overlay {
    opacity: 1;
  }
  /* .card-header1 {
    
    background-color: rgb(171, 238, 250) !important;
}
   */

  