

.numberCircle-center {
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
   
    font-size: 32px;
    /* border: 2px solid rgb(15, 1, 1); */
    margin:auto;
    
}

.numberCircle-center1{
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    background-color: rgb(223, 205, 224);
    text-decoration: none;
    color: #1b1b1a;
    font-size: 32px;
    
    /* border: 2px solid rgb(15, 1, 1); */
    margin: auto;
    
}


.numberCircle-center2{
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    background-color:#9ACD32;
    text-decoration: none;
    color: #1b1b1a;
    font-size: 32px;
    /* border: 2px solid rgb(15, 1, 1); */
    margin: auto;
    
}
.numberCircle-center3{
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    background-color:#FFFF00;
    text-decoration: none;
    color: #1b1b1a;
    font-size: 32px;
    /* border: 2px solid rgb(15, 1, 1); */
    margin: auto;
    
}
.numberCircle-center4{
    width: 120px;
    line-height: 120px;
    border-radius: 50%;
    text-align: center;
    background-color: #FF6347;
    text-decoration: none;
    color: #1b1b1a;
    font-size: 32px;
    /* border: 2px solid rgb(15, 1, 1); */
    /* margin-left: 45px; */
    margin: auto;
    
}

.card-header {
    background: #003147;
    color: #fff;
}

.card-header-stop {
    background : red;
    color : #fff;
}

.card-container {
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  
  .card-container:hover {
    transform: scale(1.09);
    /* box-shadow: 0 4px 8px rgba(92, 12, 12, 0.1); */
  }

.paddingAll20{
    padding: 20px 20px;

}