@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap");

body {
  font-family: "Poppins", sans-serif !important;
}
.secondaryBtn {
  font-size: 16px !important;
  font-weight: 500 !important;
  background: #003147 !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 8px !important;
  padding: 6px 12px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.3s !important;
}
.secondaryBtn:hover {
  transform: translateY(-5px) !important;
}
.card {
  border: none !important;
  border-radius: 12px !important;
}
.tbl-container {
  max-width: fit-content;
  max-height: fit-content;
  padding: 10px;
  font-family: "Poppins", sans-serif !important;
}
.tbl-container table {
  padding: 0px;
  margin: 0px;
}
.tbl-container .tbl-fixed {
  overflow-y: scroll;
  height: fit-content;
  max-height: 65vh;
  margin: 0px;
  /* margin-top: 50px;  */
}
.tbl-container table {
  min-width: 100%;
  border-collapse: separate;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.table-responsive-lg {
  padding: 0px !important;
}

table th {
  position: sticky !important;
  top: 0px;
  margin-top: 10px;
  text-align: center !important;
  vertical-align: middle !important;
  background-color: #003147 !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  font-size: 14px;
  padding: 4px 2px;
}

table td {
  padding: 4px;
  border: 0.5px solid rgba(133, 133, 133, 0.1);
  font-size: 14px;
}

@media (max-width: 1300px) {
  .custom-btn {
    /* Add your styles for small to medium screens here */
    font-size: 10px;
    /* other styles */
  }
}

/* Media query for extra-large screens (e.g., min-width: 1301px and above) */
@media (min-width: 1301px) {
  .custom-btn {
    /* Add your styles for extra-large screens here */
    font-size: 16px;
    /* other styles */
  }
}

.nav {
  width: max-content;
  border: none;
  border-radius: 8px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.nav .nav-link {
  border-radius: 8px;
  color: var(--Primary);
  padding: 6px 12px;
}

.nav .nav-link.active {
  color: white;
  background: #003147;
  padding: 6px 12px;
  border: none;
  border-radius: 8px;
}

.excelBtn {
  background-color: #003147;
  color: white;
  transition: background-color 0.3s, color 0.3s;
}

.excelBtn:hover {
  background-color: white;
  color: #003147;
}

.delBox{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
